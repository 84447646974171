
import { useEffect, useState } from 'react';
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import headerImage from '../../images/cover.png';
import { ReactComponent as EtherscanIcon } from "../../images/used/etherscanColor2.svg";
import { ReactComponent as TwitterIcon } from "../../images/used/twitterColor.svg";
import { ReactComponent as DiscordIcon } from "../../images/used/discordColor2.svg";
import { ReactComponent as OpenseaIcon } from "../../images/used/openseaColor2.svg";
import logo2 from "../../images/neko2.png";
import Header, { NavLink, NavLinks, PrimaryLink as LogoLink, DesktopNavLinks } from "../used/header.js";
import { ethers } from 'ethers';
import Web3Modal from "web3modal";
import { providerOptions } from "providerOptions";
import contract from '../../contract/abi.json';

const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

const addresses = require('./addresses.json');
const hashedAddresses = addresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(hashedAddresses, keccak256, { sortPairs: true });



//TODO
const contractAddress = "0x329B5D29D8B8049E215740f42E5C14aa8Be11618";
const abi = contract;
const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
});
const StyledHeader = styled(Header)`
  ${tw`pt-6  pb-6 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900  hover:border-gray-300 hover:text-gray-300  `}
  }`;

const Image1 = tw.img`w-screen -z-20 relative`
const Image2 = tw.img`w-128 mt-8 -z-20 relative items-center`
const Container = styled.div`${tw`relative -mx-8 -mt-8 flex items-center flex-col`}`;
const HeroContainer = tw.div`z-20 relative mx-auto flex flex-col`;
const Content = tw.div` relative flex flex-1 mb-24 flex-col items-center`;
const PrimaryButton = tw.button`rounded-full border-solid border-gray-900 border-0 mt-4 text-base px-8 py-4 bg-gray-900 font-bold shadow transition duration-300 bg-primary-900 text-gray-100 hocus:bg-primary-800 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
const MintButton=  tw.button`rounded-full border-solid border-gray-900 border-0 px-8 py-3 text-lg px-8 py-4 font-bold shadow transition duration-300 bg-primary-900 text-gray-100 hocus:bg-primary-800 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
const PlusButton  =  tw.button`rounded-full border-solid border-gray-900 border-0 m-2 px-5 py-3 text-sm sm:text-base bg-gray-900 font-bold shadow transition duration-300 bg-primary-900 text-gray-100 hocus:bg-primary-800 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
const MintCount  =  tw.h1`mb-4 mt-12 text-7xl text-secondary-900 text-center`;
const MintPaused  =  tw.h1`mb-0 text-2xl text-secondary-900 mt-4`;
const MintSold  =  tw.h1`mb-0 text-4xl text-secondary-900 mt-4`;
const SupplyCount  =  tw.h2` text-4xl mt-2 text-center text-secondary-900`;


export default () => {
  const [mintAmount, setMintAmount] = useState(1);
  const [instance, setInstance] = useState();
  const [account, setAccount] = useState(null);
  //const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [proof, setProof] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [supply,setSupply]=useState(0) 
  const [contract,setContract]=useState()
  const [privateMint, setPrivateMint]=useState()
  const [publicMint, setPublicMint]=useState()


const checkWhiteList = async (accounts) => {
    setIsLoading(true);
    const hashedAddress = keccak256(accounts);
    const proofJson = merkleTree.getHexProof(hashedAddress);
    

    console.log(proofJson)
    if (proofJson) setProof(proofJson);
    setIsLoading(false);
  }


  const connectWallet = async () => {
    try {
      const instance = await web3Modal.connect();
      console.log(instance)
      const provider = new ethers.providers.Web3Provider(instance);
      console.log(provider)
      const accounts = await provider.listAccounts();
      console.log(accounts)
      const network = await provider.getNetwork();
      console.log(network)
      setInstance(instance);
      if (accounts) checkWhiteList(accounts[0]);
      if (accounts) setAccount(accounts[0]); 
      const nftContract = new ethers.Contract(contractAddress, abi, provider.getSigner()); 
      console.log(nftContract)
      let totalSupply = await nftContract.totalSupply();
      let allowList = await nftContract.presaleStarted();
      let publicMint = await nftContract.publicSaleStarted();
      console.log(allowList)
      console.log(publicMint)
      //let mintLimit = await nftContract.
      setChainId(network.chainId);
      console.log(network.chainId)
      setSupply(totalSupply.toString())
      setContract(nftContract)
      setPrivateMint(allowList)
      setPublicMint(publicMint)

    } catch (error) {
      setError(error);
    }
  };


  const mintNftHandler = async () => {

    try {
      //const { ethereum } = window;
      if (contract) {
       
        console.log("Initialize payment");
        let price = parseInt(mintAmount) * .05
        price = price.toFixed(4)
        
        let nftTxn = null;
        if (publicMint){
          nftTxn = await contract.mint(mintAmount, { value: ethers.utils.parseEther(price.toString()) });
        }else if(privateMint){
          nftTxn = await contract.mintPresale(mintAmount,proof, { value: ethers.utils.parseEther(price.toString()) });
        }
        
        if(nftTxn){
          console.log("Mining... please wait");
          await nftTxn.wait();
          //todo
          console.log(`Mined, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
        }
      } else {

        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      console.log(err);
    }
  }
  //TODO
  const MAXMINT=20
  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > MAXMINT) {
      newMintAmount = MAXMINT;
    }
    setMintAmount(newMintAmount);
  };


 useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   useEffect(() => {
    if (instance?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId) => {
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        console.log("disconnect", error);
        disconnect();
      };

      instance.on("accountsChanged", handleAccountsChanged);
      instance.on("chainChanged", handleChainChanged);
      instance.on("disconnect", handleDisconnect);

      return () => {
        if (instance.removeListener) {
          instance.removeListener("accountsChanged", handleAccountsChanged);
          instance.removeListener("chainChanged", handleChainChanged);
          instance.removeListener("disconnect", handleDisconnect);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

    const connectWalletButton = () => {

        if (account){
            return(mintNftButton())
        }
        else{
            return (
                <>
                 <Image2 src={logo2} />
                <PrimaryButton onClick={connectWallet}>Connect Wallet</PrimaryButton>
                </>
            )
        }
    }


    const mintNftButton = () => {
        //TODO: make mainnet
        if(chainId===1){
            if (supply<4990){
                if (privateMint){
                    if (setIsLoading===true){
                        return(<>
                         <Image2 src={logo2} />
                        <PrimaryButton>Connecting...</PrimaryButton>
                        </>)
                    }else{
                        if(proof.length>0){
                            return (
                                    <div>
                                        <MintCount>{mintAmount}</MintCount>
                                        <div>          
                                            <PlusButton onClick={decrementMintAmount}>-</PlusButton>
                                            <MintButton onClick={mintNftHandler} >Mint</MintButton>
                                            <PlusButton onClick={incrementMintAmount}>+</PlusButton>
                                        </div>
                                        <SupplyCount>{supply} / 5000</SupplyCount>
                                    </div>
                                )
                        }else if(publicMint){
                        return (
                            <>
                                <MintCount>{mintAmount}</MintCount>
                                <div>          
                                    <PlusButton onClick={decrementMintAmount}>-</PlusButton>
                                    <MintButton onClick={mintNftHandler} > Mint</MintButton>
                                    <PlusButton onClick={incrementMintAmount}>+</PlusButton>
                                </div>
                                <SupplyCount>{supply} / 5000</SupplyCount>
                            </>
                            )

                        }else if(proof.length===0 && isLoading===false){

                            return(
                              <>
                              <Image2 src={logo2} />
                              <MintPaused>Not on Allow List :(<br/>Stay tuned for updates!</MintPaused>
                              </>
                                )
                        }
                    }
                }else if(publicMint){
                        return (
                        <>
                            <MintCount>{mintAmount}</MintCount>
                            <div>          
                                <PlusButton onClick={decrementMintAmount}>-</PlusButton>
                                <MintButton onClick={mintNftHandler} > Mint</MintButton>
                                <PlusButton onClick={incrementMintAmount}>+</PlusButton>
                            </div>
                            <SupplyCount>{supply} / 5000</SupplyCount>
                        </>
                        )
                }else{
                    return(
                        <>
                         <Image2 src={logo2} />
                        <MintPaused>Minting Paused :(<br/>Stay tuned for updates!</MintPaused>
                        </>
                        )
                }
            }else{
                return( 
                    <>
                     <Image2 src={logo2} />
                    <MintSold>Sold out!</MintSold>
                    </>
                    )
            }
        }else{
            return(
                <>
                 <Image2 src={logo2} />
                <PrimaryButton>Connecting...</PrimaryButton>
                </>)
          }

        return(
        <>
            <Image2 src={logo2} />
            <PrimaryButton>Connecting...</PrimaryButton>
        </>
         )
      
    }



  const disconnect = async () => {
      await web3Modal.clearCachedProvider();
      refreshState();
    };

  const refreshState = () => {
    setAccount(null);
    setChainId();
    setInstance();
    //setNetwork("");
    setProof([]);
    setContract()
    setSupply(0)
    //setMessage("");
    //setSignature("");
    //setVerified(undefined);
  };

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="https://opensea.io/collection/nekodarumas">
        <OpenseaIcon />
      </NavLink>
      <NavLink href="https://discord.com/invite/W53h6MEAMr">
        <DiscordIcon/>
      </NavLink>
      <NavLink href="https://twitter.com/nekodarumas">
        <TwitterIcon/>
      </NavLink>
      <NavLink href="https://etherscan.io/address/0x329B5D29D8B8049E215740f42E5C14aa8Be11618#code">
        <EtherscanIcon/>
      </NavLink>
    </NavLinks>,
    

  ];

  return (
    <Container>
       <StyledHeader links={navLinks} />
         <HeroContainer/>
        <Image1 src={headerImage} />
        
       <Content>    
       {connectWalletButton()}
        </Content>
      
    </Container>
  );
};
