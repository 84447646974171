import WalletConnect from "@walletconnect/web3-provider";
//import CoinbaseWalletSDK from "@coinbase/wallet-sdk";


export const providerOptions = {

  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: "d4ce4fe129e14ca7b8980a9be639b404"// required
    }
  }
};