import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

const Container = tw.div`relative mb-0`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-secondary-900`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/2 max-w-sm`}
`;




const Link = tw.a`inline-block mt-1 text-xl text-primary-900 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-3xl leading-none text-primary-900`}
  }

  .description1 {
    ${tw`mt-1 sm:mt-4 font-bold text-xl text-secondary-900 leading-loose`}
  }

  .description2 {
    ${tw`mt-1 sm:mt-4 font-bold text-xl text-secondary-900 leading-loose`}
  }
  }

  .description3 {
    ${tw`mt-1 sm:mt-4 text-xl font-medium text-secondary-900 leading-loose`}
  }

  .link{
     ${tw`mt-1 sm:mt-4 text-xl font-medium text-secondary-900 leading-loose hover:border-primary-800 text-primary-900 hocus:text-primary-800`}
`;

export default ({ cards = null, heading = "When and How", subheading = "Details", description = "" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      title: "When:",
      description1: "April 10th, 2022.",
      description2: "Price: .05 ETH + Gas.",
      description3: "First 48 hours - 5 darumas per wallet, after no limit.",
      

    },

    { 
      title: "How to get WL:",
      description1: "Access to WhiteList will close on April 5th, 2022.",
      description3: "To Access WL you only need to have one of these collections:",
      url1: <Link href='https://opensea.io/collection/ilyakazakov'>ilyakazakov</Link>,
      url2: <Link href="https://opensea.io/collection/metaverse-creatures">Metaverse Creatures</Link> ,
      url3: <Link href="https://opensea.io/collection/popsiki-x">Popsiki. Crypto Jumpers</Link>,
      url4: <Link href="https://opensea.io/collection/supiritto">Lucky Spirit Cards</Link>,
      url5: <Link href="https://foundation.app/ilyakazakov">Foundation</Link>   

    },
    
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description1">
                  {card.description1} 
                </p>
                <p className="description2">
                  {card.description2}     
                </p>
                <p className="description3">
                  {card.description3}     
                </p>
                <p className="link">
                  {card.url1}
                  <br/>
                  {card.url2}
                  <br/>
                  {card.url3}
                  <br/> 
                  {card.url4}
                  <br/> 
                  {card.url5}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
