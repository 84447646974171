import React from "react"
import "style.css"

//import "tailwindcss/dist/base.css"
import AnimationRevealPage from "helpers/AnimationRevealPage"
import HeaderAndMint from "components/used/HeaderAndMint"
import About from "components/used/About"
import Roadmap from "components/used/Roadmap"
import Team from "components/used/Team"
import Footer from "components/used/Footer"
require('dotenv').config()
function App() {
  return (
    <AnimationRevealPage disabled>
      <HeaderAndMint/>
    </AnimationRevealPage>
  )
}

export default App